import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"

const About = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="O nama" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter ">
            History, Present, Future...
          </h2>
        ) : (
          <h2 className="about-header visibility-filter ">
            Povijest, Sadašnjost, Budućnost…
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">About Us</h2>
        ) : (
          <h2 className="about-header mobile-visible">O nama</h2>
        )}
      </div>
      {selectedLanguage === "EN" ? (
        <div className="about-content mobile-visible ">
          <h4 className="about-paragraph">
            During its more than 75 years of existence, as the legal successor
            of GP Osijek, DP Niskogradja, i.e. GP Gradnja, since 1946,
            Osijek-Koteks d.d. was structurally adapted to new market and
            organizational models and requirements. Osijek Koteks d.d. expanded
            its basic construction activity to the exploitation of sand and
            gravel from the Drava River from the upper reaches to the confluence
            with the Danube.
          </h4>
          <h4 className="about-paragraph">
            With its many years of rich experience in the application of modern
            technology in all domains of activity, it is one of the oldest
            construction companies in Croatia. The evidence is thousands of
            kilometers of built roads with associated facilities and hundreds of
            apartments in the domain of high-rise construction. Today, Osijek
            Koteks is ready to answer all the challenges of investors. With its
            business visions, it emphasizes the construction of civil
            engineering facilities on any terrain and under any conditions.
          </h4>
          <h4 className="about-paragraph">
            Today, stationary and mobile asphalt and concrete factories enable
            us to produce large capacities with high quality transport.
            State-of-the-art machinery is used for road construction in all
            stages of construction - excavators, graders, bulldozers, combine
            harvesters, loaders, rollers, pavers... The company has its own
            facilities and capacities for the production of prestressed concrete
            elements (such as overpasses, viaducts or apartments).
            <Link to="/pravila-privatnosti">
              {" "}
              <span className="bold underline-txt">See privacy policy »</span>
            </Link>
          </h4>
        </div>
      ) : (
        <div className="about-content mobile-visible ">
          <h4 className="about-paragraph">
            Tijekom više od 75 godina postojanja, kao pravni slijednik GP
            Osijeka, DP Niskogradja, odnosno GP Gradnja, od 1946.g.,
            Osijek-Koteks d.d. se strukturno prilagođavao novim tržišnim i
            organizacijskim modelima i zahtjevima. Svoju osnovnu graditeljsku
            djelatnost je proširio na ekspoloataciju pijeska i šljunka iz rijeke
            Drave od gornjeg toka do ušća u Dunav.
          </h4>
          <h4 className="about-paragraph">
            Svojim višegodišnjim bogatim iskustvom u primjeni moderne
            tehnologije u svim domenama djelatnosti jedno je od najstarijih
            građevinskih Društava u Hrvatskoj. Dokaz su tisuće kilometara
            izgrađenih cesta s pripadajućim objektima i stotine stanova u domeni
            visokogradnje. Osijek Koteks je danas spreman odgovoriti na sve
            izazove investitora. Sa svojim poslovnim vizijama naglasak daje
            izgradnji objekata niskogradnje na bilo kojim terenima i pod bilo
            kojim uvjetima.
          </h4>
          <h4 className="about-paragraph">
            Danas nam stacionirane i mobilne tvornice asfalta i betona omogućuju
            proizvodnju velikih kapaciteta s visokom kvalitetom transporta. Za
            izgradnju cesta u svim se fazama izvedbe koristi najsuvremenija
            mehanizacija – bageri, grederi, buldozeri, kombinirke, utovarivaci,
            valjci, finišeri… Za izradu elemenata od prenapregnutog betona (kao
            što su nadvožnjaci, viadukti ili stanovi) društvo raspolaže
            vlastitim pogonim i kapacitetima.
            <Link to="/pravila-privatnosti">
              {" "}
              <span className="bold underline-txt">
                Pogledajte pravila privatnosti »
              </span>
            </Link>
          </h4>
        </div>
      )}
      {selectedLanguage === "EN" ? (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            During its more than 75 years of existence, as the legal successor
            of GP Osijek, DP Niskogradja, i.e. GP Gradnja, since 1946,
            Osijek-Koteks d.d. was structurally adapted to new market and
            organizational models and requirements. Osijek Koteks d.d. expanded
            its basic construction activity to the exploitation of sand and
            gravel from the Drava River from the upper reaches to the confluence
            with the Danube.With its many years of rich experience in the
            application of modern technology in all domains of activity, it is
            one of the oldest construction companies in Croatia. The evidence is
            thousands of kilometers of built roads with associated facilities
            and hundreds of apartments in the domain of high-rise construction.
            Today, Osijek Koteks is ready to answer all the challenges of
            investors. With its business visions, it emphasizes the construction
            of civil engineering facilities on any terrain and under any
            conditions.Today, stationary and mobile asphalt and concrete
            factories enable us to produce large capacities with high quality
            transport. State-of-the-art machinery is used for road construction
            in all stages of construction - excavators, graders, bulldozers,
            combine harvesters, loaders, rollers, pavers... The company has its
            own facilities and capacities for the production of prestressed
            concrete elements (such as overpasses, viaducts or apartments).
            <Link to="/pravila-privatnosti">
              {" "}
              <span className="bold underline-txt">See privacy policy »</span>
            </Link>
          </h4>
        </div>
      ) : (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            Tijekom više od 75 godina postojanja, kao pravni sljednik GP
            Osijeka, DP Niskogradnja, odnosno GP Gradnja, od 1946.g.,
            Osijek-Koteks d.d. se strukturno prilagođavao novim tržišnim i
            organizacijskim modelima i zahtjevima. Svoju osnovnu graditeljsku
            djelatnost je proširio na eksploataciju pijeska i šljunka iz rijeke
            Drave od gornjeg toka do ušća u Dunav. Svojim višegodišnjim bogatim
            iskustvom u primjeni moderne tehnologije u svim domenama djelatnosti
            jedno je od najstarijih građevinskih Društava u Hrvatskoj. Dokaz su
            tisuće kilometara izgrađenih cesta s pripadajućim objektima i
            stotine stanova u domeni visokogradnje. Osijek Koteks je danas
            spreman odgovoriti na sve izazove investitora. Sa svojim poslovnim
            vizijama naglasak daje izgradnji objekata niskogradnje na bilo kojim
            terenima i pod bilo kojim uvjetima. Danas nam stacionirane i mobilne
            tvornice asfalta i betona omogućuju proizvodnju velikih kapaciteta s
            visokom kvalitetom transporta. Za izgradnju cesta u svim se fazama
            izvedbe koristi najsuvremenija mehanizacija – bageri, grederi,
            buldožeri, kombinirke, utovarivači, valjci, finišeri… Za izradu
            elemenata od prenapregnutog betona (kao što su nadvožnjaci,
            vijadukti ili stanovi) društvo raspolaže vlastitim pogonom i
            kapacitetima.
            <Link to="/pravila-privatnosti">
              {" "}
              <span className="bold underline-txt">
                Pogledajte pravila privatnosti »
              </span>
            </Link>
          </h4>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "mainBcg1-min.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default About
